<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for activities"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="'Community Activities'" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                :to="`/admin/cd/community-activities/create`"
              >
                <v-icon class="mr-2"> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">
                      {{
                        sort
                          ? sort == "metode"
                            ? "Metode"
                            : sort == "tanggal_pelaksanaan"
                            ? "Tanggal Pelaksanaan"
                            : "Tujuan"
                          : "Sort"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize"
                        text
                      >
                        <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                        Sort
                      </v-btn>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'metode'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'metode' ? true : false"
                      text
                      @click="changeSort('metode')"
                    >
                      Metode
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'tanggal_pelaksanaan'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'tanggal_pelaksanaan' ? true : false"
                      text
                      @click="changeSort('tanggal_pelaksanaan')"
                    >
                      Tanggal Pelaksanaan
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'tujuan'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'tujuan' ? true : false"
                      text
                      @click="changeSort('tujuan')"
                    >
                      Tujuan
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card flat class="card_on_view">
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
        >
          <template v-slot:[`item.metode`]="{ item }">
            <h4 class="not_bold text-capitalize">
              {{ item.metode }}
            </h4>
          </template>
          <template v-slot:[`item.instansi_partner.instansi.nama_instansi`]="{ item }">
            <div class="d-flex align-center">
              <h4
                v-for="(partner, n) in item.instansi_partner"
                :key="n"
                class="text-capitalize not_bold"
              >
                {{
                  n <= item.instansi_partner.length - 1 && n != 0 ? ",  " : ""
                }}
                {{ partner.instansi.nama_instansi }}
              </h4>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="toDetail(item, 'show')"
                  >
                    <v-icon dark small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Detail</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="toDetail(item, 'edit')"
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Activities</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Hapus Associate</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "communityActivities",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      menuLimit: false,
      sort_menu: false,
      loading: true,
      dataSet: null,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      find: "",
      sort: "",
      icon_sort: "mdi-sort-ascending",
      headers: [
        { text: "Nama Kegiatan", value: "nama" },
        { text: "Tanggal Pelaksanaan", value: "tanggal_pelaksanaan" },
        { text: "Metode", value: "metode" },
        { text: "Tujuan", value: "tujuan" },
        { text: "Partner", value: "instansi_partner.instansi.nama_instansi" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Community Activities",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toDetail(item, type) {
      if (type == "show") {
        this.$router.push(`/admin/cd/community-activities/detail/${item.id}`);
      } else {
        this.$router.push(`/admin/cd/community-activities/update/${item.id}`);
      }
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/community-development/event/delete`,
        deleteType: "Community Activities",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Activities";
      this.d_small = true;
      this.line = true;
    },
    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/community-development/event/list?page[size]=${
          this.limit
        }&page[number]=${this.page}&filter[nama]=${this.find}&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}&include=instansi_partner`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data.data.data;
          this.last_page = data.data.last_page;
          this.total_page = data.data.total;
          this.dataSet.forEach((item) => {
            item.instansi_partner = item.instansi_partner;
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.grey {
  background: gray !important;
}
</style>
